<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-subheader>
        <b>
          <h1>In Progress</h1>
        </b>
      </v-subheader>
      <v-spacer></v-spacer>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters>
          <v-col cols="6">
            <v-text-field v-model="search" placeholder="Search Files"></v-text-field>
          </v-col>
          <v-col cols="6">
            <div class="float-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-img @click="generateReport()" max-height="50" v-on="on" :src="require('../assets/excel.png')">
                  </v-img>
                </template>
                <span>Export to Excel</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-tabs center-active centered>
          <v-tab v-for="item in EnquiryTypes" :key="item.id" @click="fileStatus(item.id)">{{ item.name }}</v-tab>
        </v-tabs>
        <v-data-table :headers="headers" :items="FilterData" :search="search" class="elevation-1" dense>
          <template v-slot:[`item.index`]="{ item }">
            {{ FilterData.indexOf(item) + 1 }}
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ item.enquiry_type.name }}
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <v-tooltip bottom v-if="item.description">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ item.name }}</span>
              </template>
              <span>{{ item.description }}</span>
            </v-tooltip>
            <span v-else>{{ item.name }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="actionmodel(item.id, 1)" color="warning" class="px-1" small>
                  mdi-information-off-outline</v-icon>
              </template><span>Not Sufficient Info</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="actionmodel(item.id, 3)" color="error" class="px-1" small>mdi-delete</v-icon>
              </template><span>Other Reason</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="actionmodel(item.id, 2)" color="yellow" class="px-1" small>mdi-cancel</v-icon>
              </template><span>Spam</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="actionmodel(item.id, 4)" color="primary" class="px-1" small>mdi-pencil
                </v-icon>
              </template><span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom v-if="item.remarks">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="success" class="px-1" small>mdi-eye
                </v-icon>
              </template><span>{{item.remarks}}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!--Confirm -->
    <v-dialog v-model="confirmDialog" width="40%" persistent>
      <v-card>
        <v-card-title>
          <v-subheader>
            <b>
              <h1>Are you sure ?</h1>
            </b>
          </v-subheader>
        </v-card-title>
        <v-card-text>
          <v-col>
            <v-textarea v-model="action.remarks" outlined dense>
              <template slot="label">Remarks</template>
            </v-textarea>
          </v-col>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col>
            <div class="float-right">
              <v-btn class="mx-1" color="success" outlined dark @click="actions()">Yes</v-btn>
              <v-btn class="mx-1" color="error" outlined dark @click="confirmDialog = false">No</v-btn>
            </div>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { saveAs } from "file-saver";
import moment from "moment";
import WebService from "@/service/WebService";
export default {
  data() {
    return {
      search: "",
      Files: [],
      Users: [],
      EnquiryTypes: [],
      FilterData: [],
      newData: {},
      action: {
        id: "",
        reason_id: "",
        remarks: "",
      },
      confirmDialog: false,
      errors: {},
      headers: [
        { text: "#", value: "index" },
        { text: "Name", value: "name" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Type", value: "type" },
        { text: "Assigned To", value: "user.name" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      // Rules
      whiteSpaceRules: [
        (v) => /^(?!\s)[a-zA-Z0-9_\s-]*$/.test(v) || "Enter Valid Value",
      ],
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      numberRules: [
        (v) => !!v || "This field is required.",
        (v) => /^\d{0,9}(\.\d{1,3})?$/.test(v) || "Enter Valid Amount",
      ],
    };
  },
  mounted() {
    this.getFiles();
    this.getEnquiryTypes();
  },
  methods: {
    getFiles() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("web-enquiry?in_progress=1").then((response) => {
        this.Files = response.data.data.enquiries.data;
        this.FilterData = this.Files;
        this.$refs.commonManage.open(1, false);
      });
    },
    getEnquiryTypes() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("enquiry-types").then((response) => {
        this.EnquiryTypes = response.data.data.types;
        this.EnquiryTypes.unshift({ id: 0, name: "All" });
        this.$refs.commonManage.open(1, false);
      });
    },
    fileStatus(params) {
      if (params == 0) {
        this.FilterData = this.Files;
      } else {
        this.FilterData = this.Files.filter(function (value) {
          return value.enquiry_type_id == params;
        });
      }
    },
    actionmodel(id, reason_id) {
      if (reason_id != 4) {
        this.action.id = id;
        this.action.reason_id = reason_id;
        this.action.remarks = "";
        this.confirmDialog = true;
      } else {
        this.editFile(id);
      }
    },
    actions() {
      let flag = true;
      if (this.action.reason_id == 3 && this.action.remarks == "") {
        flag = false;
      }
      if (flag) {
        this.$refs.commonManage.open(1, true);
        WebService.putCall("web-enquiry/" + this.action.id, this.action).then(
          (response) => {
            if (response.data.status == 200) {
              this.confirmDialog = false;
              this.$refs.commonManage.open(1, false);
              this.getFiles();
              let data = {};
              data.message = response.data.data;
              data.colour = "success";
              this.$refs.commonManage.open(3, data);
            }
            this.$refs.commonManage.open(1, false);
          }
        );
      } else {
        let data = {};
        data.message = "Remarks cannot be null";
        data.colour = "error";
        this.$refs.commonManage.open(3, data);
      }
    },
    generateReport() {
      this.$refs.commonManage.open(1, true);
      WebService.fileBuffer(
        "web-enquiry?in_progress=1&type=excel"
      ).then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `Report_${new Date().getTime()}.xlsx`);
        this.$refs.commonManage.open(1, false);
      });
    },
    editFile(params) {
      this.$router.push("/file/" + params);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    dateFormatted(params) {
      return params ? moment(params).format("D-M-Y") : "";
    },
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("loggedIn") === "true") {
      next();
    } else {
      next("/");
    }
  },
};
</script>
