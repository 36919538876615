import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/LoginPage.vue'
import PageNotFound from '@/Errors/NotFound.vue'
import Dashboard from '@/components/DashboardPage.vue'
import Unassigned from '@/components/UnassignedPage.vue'
import LeadsWon from '@/components/LeadsWon.vue'
import LeadsLoss from '@/components/LeadsLoss.vue'
import SpamLeads from '@/components/SpamLeads.vue'
import SelfAssigned from '@/components/SelfAssigned.vue'
import Assigned from '@/components/AssignedPage.vue'
import InProgress from '@/components/InProgress.vue'
import FileDetails from '@/components/FileDetails.vue'
import User from '@/components/UserPage.vue'
import Report from '@/components/FileReport.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Login', component: Login, meta: { public: false } },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, meta: { public: true } },
  { path: '/user', name: 'User', component: User, meta: { public: true } },
  { path: '/unassigned', name: 'Unassigned', component: Unassigned, meta: { public: true } },
  { path: '/leads-won', name: 'LeadsWon', component: LeadsWon, meta: { public: true } },
  { path: '/leads-loss', name: 'LeadsLoss', component: LeadsLoss, meta: { public: true } },
  { path: '/leads-spam', name: 'SpamLeads', component: SpamLeads, meta: { public: true } },
  { path: '/selfassigned', name: 'SelfAssigned', component: SelfAssigned, meta: { public: true } },
  { path: '/in-progress', name: 'InProgress', component: InProgress, meta: { public: true } },
  { path: '/assigned', name: 'Assigned', component: Assigned, meta: { public: true } },
  { path: '/file/:id', name: 'FileDetails', component: FileDetails, meta: { public: true } },
  { path: '/reports', name: 'Report', component: Report, meta: { public: true } },
  { path: "*", component: PageNotFound, meta: { public: false } },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
