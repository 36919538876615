<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-subheader><b>
          <h1>Users</h1>
        </b></v-subheader>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="addModel = true">Add New User</v-btn>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-col cols="6">
          <v-text-field v-model="search" placeholder="Search Users"></v-text-field>
        </v-col>
        <v-data-table :headers="headers" :items="Users" :search="search" class="elevation-1" dense>
          <template v-slot:[`item.index`]="{ item }">
            {{ Users.indexOf(item) + 1 }}
          </template>
          <template v-slot:[`item.department`]="{ item }">
            <span v-if="item.user_type_id == 2"> Admin </span>
            <span v-else> Sales </span>
          </template>
          <template v-slot:[`item.mobile`]="{ item }">
            <span v-if="item.mobile"> {{ item.mobile }} </span>
            <span v-else> - </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="editUser(item.id)" color="primary" small>mdi-pencil</v-icon>
              </template><span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="deleteUser(item.id)" color="error" small>mdi-delete</v-icon>
              </template><span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!--Add -->
    <v-dialog v-model="addModel" width="60%">
      <v-card>
        <v-card-title>
          <v-subheader><b>
              <h1>Add New User</h1>
            </b></v-subheader>
          <v-spacer></v-spacer>
          <v-icon color="error" @click="addModel = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text class="pt-7">
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="newData.name" :rules="requiredRules.concat(whiteSpaceRules)"
                  :error-messages="errors.name" outlined dense>
                  <template slot="label">Name<span style="color: orange"><b>*</b></span></template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select v-model="newData.user_type" :items="Departments" item-name="text" item-value="value"
                  :rules="requiredRules" outlined dense>
                  <template slot="label">Department<span style="color: orange"><b>*</b></span></template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="newData.email" :rules="requiredRules.concat(emailRules)"
                  :error-messages="errors.email" outlined dense>
                  <template slot="label">Email<span style="color: orange"><b>*</b></span></template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="newData.mobile" :rules="phoneRules" outlined dense>
                  <template slot="label">Phone</template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col class="py-0">
              <div class="float-right">
                <v-btn class="mx-1" color="warning" outlined dark @click="reset()">Clear</v-btn>
                <v-btn class="mx-1" color="success" outlined dark @click="addUser()">Add</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Edit -->
    <v-dialog v-model="editModel" width="60%">
      <v-card>
        <v-card-title>
          <v-subheader><b>
              <h1>Edit User</h1>
            </b></v-subheader>
          <v-spacer></v-spacer>
          <v-icon color="error" @click="editModel = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text class="pt-7">
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="editData.name" :rules="requiredRules.concat(whiteSpaceRules)"
                  :error-messages="errors.name" outlined dense>
                  <template slot="label">Name<span style="color: orange"><b>*</b></span></template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select v-model="editData.user_type" :disabled="editData.user_type == 1 ? true : false" readonly
                  :items="Departments" item-name="text" item-value="value" :rules="requiredRules" outlined dense>
                  <template slot="label">Department<span style="color: orange"><b>*</b></span></template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="editData.email" :rules="requiredRules.concat(emailRules)"
                  :error-messages="errors.email" outlined dense readonly>
                  <template slot="label">Email<span style="color: orange"><b>*</b></span></template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="editData.mobile" :rules="phoneRules" outlined dense>
                  <template slot="label">Phone</template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col class="py-0">
              <div class="float-right">
                <v-btn class="mx-1" color="warning" outlined dark @click="resetEdit()">Clear</v-btn>
                <v-btn class="mx-1" color="success" outlined dark @click="updateUser()">Update</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import WebService from "@/service/WebService";
export default {
  data() {
    return {
      search: "",
      Users: [],
      Departments: [
        { text: "Admin", value: 2 },
        { text: "Sales", value: 3 },
      ],
      addModel: false,
      editModel: false,
      editData: {},
      newData: {},
      errors: {},
      headers: [
        { text: "#", value: "index" },
        { text: "Name", value: "name" },
        { text: "Department", value: "department" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "mobile" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      // Rules
      whiteSpaceRules: [
        (v) => /^(?!\s)[a-zA-Z0-9_\s-]*$/.test(v) || "Enter Valid Value",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      phoneRules: [
        // eslint-disable-next-line no-useless-escape
        (v) =>
          v &&
            v.length > 0 &&
            // eslint-disable-next-line no-useless-escape
            !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(v)
            ? "Enter Valid Number"
            : true,
      ],
      emailRules: [
        (v) =>
          v && v.length > 0 && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v)
            ? "Enter Valid Email"
            : true,
      ],
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("users").then((response) => {
        this.Users = response.data.data.data;
        this.$refs.commonManage.open(1, false);
      });
    },
    addUser() {
      if (this.$refs.form.validate()) {
        this.$refs.commonManage.open(1, true);
        WebService.postCall("users", this.newData).then((response) => {
          if (response.data.status == 200) {
            this.$refs.commonManage.open(1, false);
            this.addModel = false;
            this.reset()
            this.getUsers();
            let data = {};
            data.message = response.data.message;
            data.colour = "success";
            this.$refs.commonManage.open(3, data);
          } else if (response.data.status == 400){
            this.errors = response.data.errors;
          }
          this.$refs.commonManage.open(1, false);
        });
      }
    },
    editUser(params) {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("users/" + params).then((response) => {
        if (response.status == 200) {
          this.$refs.commonManage.open(1, false);
          this.editData = response.data.data;
          this.editData.user_type = response.data.data.user_type_id;
          this.editModel = true;
        }
        this.$refs.commonManage.open(1, false);
      });
    },
    updateUser() {
      if (this.$refs.form.validate()) {
        this.$refs.commonManage.open(1, true);
        WebService.putCall("users/" + this.editData.id, this.editData).then(
          (response) => {
            if (response.status == 200) {
              this.$refs.commonManage.open(1, false);
              this.editModel = false;
              let data = {};
              this.getUsers();
              data.message = response.data.message;
              data.colour = "success";
              this.$refs.commonManage.open(3, data);
            }
            this.$refs.commonManage.open(1, false);
          }
        );
      }
    },
    async deleteUser(params) {
      if (await this.$refs.commonManage.confirm()) {
        WebService.deleteCall("users/" + params).then((response) => {
          if (response.status == 200) {
            this.$refs.commonManage.open(1, false);
            this.getUsers();
            let data = {};
            data.message = response.data.message;
            data.colour = "success";
            this.$refs.commonManage.open(3, data);
          }
        });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetEdit() {
      this.editData.name = ''
      this.editData.mobile = ''
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    dateFormatted(params) {
      return params ? moment(params).format("D-M-Y") : "";
    },
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("loggedIn") === "true") {
      next();
    } else {
      next("/");
    }
  },
};
</script>
