export default [
  { icon: "mdi-chart-line", text: "Dashboard",sub_menu_status: false, link: "dashboard", user_id:[1,2,3] },
  { icon: "mdi-account-group", text: "User",sub_menu_status: false, link: "user", user_id:[1,2] },
  {
    icon: "mdi-clipboard", text: "Leads", sub_menu_status: true, user_id:[1,2,3], sub_menus: [
      { icon: "mdi-clipboard-minus", text: "Unassigned",sub_menu_status: false, link: "unassigned", user_id:[1,2] },
      { icon: "mdi-clipboard-check", text: "Assigned",sub_menu_status: false, link: "assigned", user_id:[1,2,3] },
      { icon: "mdi-clipboard-pulse", text: "In Progress",sub_menu_status: false, link: "in-progress", user_id:[1,2,3] },
      { icon: "mdi-clipboard-remove", text: "Rejected",sub_menu_status: false, link: "leads-spam", user_id:[1,2,3] },
      { icon: "mdi-clipboard-account", text: "Self Assigned",sub_menu_status: false, link: "selfassigned", user_id:[1,2] },
    ]
  },
  {
    icon: "mdi-account-multiple", text: "Deals", sub_menu_status: true, user_id:[1,2,3], sub_menus: [
      { icon: "mdi-account-multiple-check", text: "Won", sub_menu_status: false, link: "leads-won", user_id:[1,2,3] },
      { icon: "mdi-account-multiple-minus", text: "Lost", sub_menu_status: false, link: "leads-loss", user_id:[1,2,3] },
    ]
  },
  { icon: "mdi-file-document", text: "Reports", link: "reports", user_id:[1,2] },
]

// User Types
// -------------
// 1-Super Admin
// 2-Admin
// 3-Back Office Staff
// 4-Instructor
// 5-Student