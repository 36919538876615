<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-subheader>
        <b>
          <h1>Unassigned</h1>
        </b>
      </v-subheader>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="addModel = true">Add New Lead</v-btn>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters>
          <v-col cols="6">
            <v-text-field v-model="search" placeholder="Search Files"></v-text-field>
          </v-col>
          <v-col cols="6">
            <div class="float-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-img @click="generateReport()" max-height="50" v-on="on" :src="require('../assets/excel.png')">
                  </v-img>
                </template>
                <span>Export to Excel</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-tabs center-active centered>
          <v-tab v-for="item in EnquiryTypes_All" :key="item.id" @click="fileStatus(item.id)">{{ item.name }}</v-tab>
        </v-tabs>
        <v-data-table :headers="headers" :items="FilterData" :search="search" class="elevation-1" dense>
          <template v-slot:[`item.index`]="{ item }">
            {{ FilterData.indexOf(item) + 1 }}
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <v-tooltip bottom v-if="item.description">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ item.name }}</span>
              </template>
              <span>{{ item.description }}</span>
            </v-tooltip>
            <span v-else>{{ item.name }}</span>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ item.enquiry_type.name }}
          </template>
          <template v-slot:[`item.assign_to`]="{ item }">
            <v-select style="max-width: 150px" placeholder="Select User" v-model="item.assign_to_user" :items="Users"
              item-text="name" item-value="id" dense hide-details @change="assign(item)"
              :menu-props="{ offsetY: true }">
            </v-select>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="actionmodel(item.id, 1)" color="warning" class="px-1" small>
                  mdi-information-off-outline</v-icon>
              </template><span>Not Sufficient Info</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="actionmodel(item.id, 3)" color="error" class="px-1" small>mdi-delete</v-icon>
              </template><span>Other Reason</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="actionmodel(item.id, 2)" color="yellow" class="px-1" small>mdi-cancel</v-icon>
              </template><span>Spam</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="actionmodel(item.id, 4)" color="primary" class="px-1" small>mdi-pencil
                </v-icon>
              </template><span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom v-if="item.remarks">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="success" class="px-1" small>mdi-eye
                </v-icon>
              </template><span>{{item.remarks}}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!--Add -->
    <v-dialog v-model="addModel" width="60%">
      <v-card>
        <v-card-title>
          <v-subheader><b>
              <h1>Add New Lead</h1>
            </b></v-subheader>
          <v-spacer></v-spacer>
          <v-icon color="error" @click="addModel = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text class="pt-7">
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-select v-model="newData.communication_type_id" :items="CommunicationTypes" item-text="name"
                  item-value="id" :rules="requiredRules" outlined dense>
                  <template slot="label">Communication Type<span style="color: orange"><b>*</b></span></template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select v-model="newData.enquiry_type_id" :items="EnquiryTypes" item-text="name" item-value="id"
                  :rules="requiredRules" outlined dense>
                  <template slot="label">Enquiry Type<span style="color: orange"><b>*</b></span></template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="newData.name" :rules="requiredRules" :error-messages="errors.name" outlined
                  dense>
                  <template slot="label">Name<span style="color: orange"><b>*</b></span></template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select v-model="newData.assigned_to" :items="Users" item-text="name" item-value="id"
                  :rules="requiredRules" outlined dense>
                  <template slot="label">Assign To<span style="color: orange"><b>*</b></span></template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="newData.phone" :rules="phoneRules" :error-messages="errors.phone" outlined dense>
                  <template slot="label">Phone<span style="color: orange"><b>*</b></span></template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="newData.email" :rules="emailRules" outlined dense :error-messages="errors.email">
                  <template slot="label">Email</template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="newData.description" outlined dense :error-messages="errors.description">
                  <template slot="label">Description</template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col class="py-0">
              <div class="float-right">
                <v-btn class="mx-1" color="warning" outlined dark @click="reset()">Clear</v-btn>
                <v-btn class="mx-1" color="success" outlined dark @click="addNewLead()">Add</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Confirm -->
    <v-dialog v-model="confirmDialog" width="40%" persistent>
      <v-card>
        <v-card-title>
          <v-subheader>
            <b>
              <h1 v-if="action.reason_id == 1">
                Are you sure, it is having insufficient data?
              </h1>
              <h1 v-if="action.reason_id == 2">
                Are you sure, to mark it as spam?
              </h1>
              <h1 v-if="action.reason_id == 3">Are you sure?</h1>
            </b>
          </v-subheader>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-col>
              <v-textarea v-model="action.remarks" outlined dense :rules="requiredRules">
                <template slot="label">Remarks</template>
              </v-textarea>
            </v-col>
          </v-form>
          <v-row>
            <v-col>
              <div class="float-right">
                <v-btn class="mx-1" color="success" outlined dark @click="actions()">Yes</v-btn>
                <v-btn class="mx-1" color="error" outlined dark @click="confirmDialog = false; reset();">No</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import moment from "moment";
import WebService from "@/service/WebService";
export default {
  data() {
    return {
      search: "",
      Files: [],
      FilterData: [],
      Users: [],
      EnquiryTypes: [],
      EnquiryTypes_All: [],
      CommunicationTypes: [],
      remarks: "",
      addModel: false,
      confirmDialog: false,
      newData: {
        email: "",
        description: "",
      },
      errors: {},
      action: {
        id: "",
        reason_id: "",
        remarks: "",
      },
      headers: [
        { text: "#", value: "index" },
        { text: "Name", value: "name" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Type", value: "type" },
        { text: "Assign To", value: "assign_to" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      // Rules
      whiteSpaceRules: [
        (v) => /^(?!\s)[a-zA-Z0-9_\s-]*$/.test(v) || "Enter Valid Value",
      ],
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      numberRules: [
        (v) => !!v || "This field is required.",
        (v) => /^\d{0,9}(\.\d{1,3})?$/.test(v) || "Enter Valid Amount",
      ],
      emailRules: [
        (v) =>
          v && v.length > 0 && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v)
            ? "Enter Valid Email"
            : true,
      ],
      phoneRules: [
        // eslint-disable-next-line no-useless-escape
        (v) =>
          v &&
            v.length > 0 &&
            // eslint-disable-next-line no-useless-escape
            !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(v)
            ? "Enter Valid Number"
            : true,
      ],
    };
  },
  mounted() {
    this.getFiles();
    this.getEnquiryTypesAll();
    this.getUsers();
    this.getCommunicationTypes();
    this.getEnquiryTypes();

  },
  methods: {
    getFiles() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("web-enquiry?assigned=0").then((response) => {
        this.Files = response.data.data.enquiries.data;
        this.FilterData = this.Files;
        this.$refs.commonManage.open(1, false);
      });
    },
    generateReport() {
      this.$refs.commonManage.open(1, true);
      WebService.fileBuffer(
        "web-enquiry?assigned=0&type=excel"
      ).then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `Report_${new Date().getTime()}.xlsx`);
        this.$refs.commonManage.open(1, false);
      });
    },
    getEnquiryTypesAll() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("enquiry-types").then((response) => {
        this.EnquiryTypes_All = response.data.data.types;
        this.EnquiryTypes_All.unshift({ id: 0, name: "All" });
        this.$refs.commonManage.open(1, false);
      });
    },
    getEnquiryTypes() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("enquiry-types").then((response) => {
        this.EnquiryTypes = response.data.data.types;
        this.$refs.commonManage.open(1, false);
      });
    },
    getCommunicationTypes() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("communication-types").then((response) => {
        this.CommunicationTypes = response.data.data.types;
        this.$refs.commonManage.open(1, false);
      });
    },
    async assign(params) {
      if (await this.$refs.commonManage.assign()) {
        this.$refs.commonManage.open(1, true);
        WebService.postCall("web-leads-assignment", {
          web_enquiry_id: params.id,
          assigned_to: params.assign_to_user,
        }).then(() => {
          this.getFiles();
          this.$refs.commonManage.open(1, false);
        });
      } else {
        params.assign_to_user = ''
      }
    },
    getUsers() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("users?assign=1").then((response) => {
        this.Users = response.data.data.data;
        this.$refs.commonManage.open(1, false);
      });
    },
    fileStatus(params) {
      if (params == 0) {
        this.FilterData = this.Files;
      } else {
        this.FilterData = this.Files.filter(function (value) {
          return value.enquiry_type_id == params;
        });
      }
    },

    addNewLead() {
      if (this.$refs.form.validate()) {
        this.$refs.commonManage.open(1, true);
        WebService.postCall("web-enquiry", this.newData).then((response) => {
          if (response.data.status == 200) {
            this.$refs.commonManage.open(1, false);
            let data = {};
            data.message = response.data.message;
            data.colour = "success";
            this.$refs.commonManage.open(3, data);
            this.addModel = false;
            this.reset()
          } else {
            this.$refs.commonManage.open(1, false);
            this.errors = response.data.errors;
            let data = {};
            data.message = response.data.message;
            data.colour = "warning";
            this.$refs.commonManage.open(3, data);
          }
        });
      }
    },
    actionmodel(id, reason_id) {
      if (reason_id != 4) {
        this.action.id = id;
        this.action.reason_id = reason_id;
        // this.action.remarks = "";
        this.confirmDialog = true;
      } else {
        this.editFile(id);
      }
    },
    actions() {
      if (this.$refs.form.validate()) {
        this.$refs.commonManage.open(1, true);
        WebService.putCall("web-enquiry/" + this.action.id, this.action).then(
          (response) => {
            if (response.data.status == 200) {
              this.confirmDialog = false;
              this.reset()
              this.$refs.commonManage.open(1, false);
              this.getFiles();
              let data = {};
              data.message = response.data.data;
              data.colour = "success";
              this.$refs.commonManage.open(3, data);
            }
          }
        );
      }
    },
    editFile(params) {
      this.$router.push("/file/" + params);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    dateFormatted(params) {
      return params ? moment(params).format("D-M-Y") : "";
    },
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("loggedIn") === "true") {
      next();
    } else {
      next("/");
    }
  },
};
</script>
