var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('common-manage',{ref:"commonManage"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-subheader',[_c('b',[_c('h1',[_vm._v("Assigned")])])]),_c('v-spacer')],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"placeholder":"Search Files"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"float-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-img',_vm._g({attrs:{"max-height":"50","src":require('../assets/excel.png')},on:{"click":function($event){return _vm.generateReport()}}},on))]}}])},[_c('span',[_vm._v("Export to Excel")])])],1)])],1),_c('v-tabs',{attrs:{"center-active":"","centered":""}},_vm._l((_vm.EnquiryTypes),function(item){return _c('v-tab',{key:item.id,on:{"click":function($event){return _vm.fileStatus(item.id)}}},[_vm._v(_vm._s(item.name))])}),1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.FilterData,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.FilterData.indexOf(item) + 1)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.enquiry_type.name)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.description)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])]):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"px-1",attrs:{"color":"warning","small":""},on:{"click":function($event){return _vm.actionmodel(item.id, 1)}}},on),[_vm._v(" mdi-information-off-outline")])]}}],null,true)},[_c('span',[_vm._v("Not Sufficient Info")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"px-1",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.actionmodel(item.id, 3)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Other Reason")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"px-1",attrs:{"color":"yellow","small":""},on:{"click":function($event){return _vm.actionmodel(item.id, 2)}}},on),[_vm._v("mdi-cancel")])]}}],null,true)},[_c('span',[_vm._v("Spam")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"px-1",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.actionmodel(item.id, 4)}}},on),[_vm._v("mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),(item.remarks)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"px-1",attrs:{"color":"success","small":""}},on),[_vm._v("mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.remarks))])]):_vm._e()]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"width":"40%","persistent":""},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',[_c('v-card-title',[_c('v-subheader',[_c('b',[(_vm.action.reason_id == 1)?_c('h1',[_vm._v(" Are you sure, it is having insufficient data? ")]):_vm._e(),(_vm.action.reason_id == 2)?_c('h1',[_vm._v(" Are you sure, to mark it as spam? ")]):_vm._e(),(_vm.action.reason_id == 3)?_c('h1',[_vm._v("Are you sure?")]):_vm._e()])])],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-col',[_c('v-textarea',{attrs:{"outlined":"","dense":"","rules":_vm.requiredRules},model:{value:(_vm.action.remarks),callback:function ($$v) {_vm.$set(_vm.action, "remarks", $$v)},expression:"action.remarks"}},[_c('template',{slot:"label"},[_vm._v("Remarks")])],2)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-col',[_c('div',{staticClass:"float-right"},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":"success","outlined":"","dark":""},on:{"click":function($event){return _vm.actions()}}},[_vm._v("Yes")]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"error","outlined":"","dark":""},on:{"click":function($event){_vm.confirmDialog = false; _vm.reset();}}},[_vm._v("No")])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }