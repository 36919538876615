<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-subheader>
        <b>
          <h1>Reports</h1>
        </b>
      </v-subheader>
      <v-spacer></v-spacer>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="3">
          <v-select v-model="newData.enquiry_type_id" :items="EnquiryTypes" item-text="name" item-value="id"
            :rules="requiredRules" outlined dense>
            <template slot="label">Enquiry Type<span style="color: orange"><b>*</b></span></template>
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="newData.from_date" label="From" prepend-inner-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on" outlined dense></v-text-field>
            </template>
            <v-date-picker v-model="newData.from_date" @input="menu1 = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="newData.to_date" label="To" prepend-inner-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on" outlined dense></v-text-field>
            </template>
            <v-date-picker :min="newData.from_date" v-model="newData.to_date" @input="menu2 = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-btn class="mx-1" color="warning" outlined dark @click="reset()">Reset
          </v-btn>
          <v-btn class="mx-1" color="success" outlined dark @click="generateReport()">Generate Report
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import moment from "moment";
import WebService from "@/service/WebService";
export default {
  data() {
    return {
      menu1: "",
      menu2: "",
      EnquiryTypes: [],
      newData: {
        from_date: "",
        to_date: "",
        enquiry_type_id: "",
      },
      // Rules
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  mounted() {
    this.getEnquiryTypes();
  },
  methods: {
    getEnquiryTypes() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("enquiry-types").then((response) => {
        this.EnquiryTypes = response.data.data.types;
        this.EnquiryTypes.unshift({ id: -1, name: "All" });
        this.$refs.commonManage.open(1, false);
      });
    },
    generateReport() {
      if (this.$refs.form.validate()) {
        this.$refs.commonManage.open(1, true);
        WebService.fileBuffer(
          "web-enquiry-export?date_from=" +
          this.newData.from_date +
          "&date_to=" +
          this.newData.to_date +
          "&type=" +
          this.newData.enquiry_type_id
        ).then((response) => {
          this.reset()
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, `Report_${new Date().getTime()}.xlsx`);
          this.$refs.commonManage.open(1, false);
        });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    dateFormatted(params) {
      return params ? moment(params).format("D-M-Y") : "";
    },
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("loggedIn") === "true") {
      next();
    } else {
      next("/");
    }
  },
};
</script>
