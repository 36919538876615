<template>
  <v-app id="inspire">
    <!-- Menu -->
    <v-navigation-drawer app width="225px" v-model="drawer" v-if="$route.meta.public">
      <v-list-item class="px-2 my-n1">
        <v-list-item-avatar>
          <v-img :src="require('./assets/logo.png')"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Quick Start </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list dense>
        <template v-for="item in menu">
          <template v-if="item.user_id.includes(user_type_id)">
            <v-list-group v-if="item.sub_menu_status" :key="item.name" :prepend-icon="item.icon" no-action
              color="indigo">
              <template v-slot:activator>
                <v-list-item-title>{{  item.text  }}</v-list-item-title>
              </template>
              <template v-for="(child, i) in item.sub_menus">
                <v-list-item v-if="child.user_id.includes(user_type_id)" :key="i" link :to="'/' + child.link">
                  <v-list-item-icon>
                    <v-icon>{{  child.icon  }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{  child.text  }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list-group>
            <v-list-item :key="item.text" link :to="'/' + item.link" v-else color="indigo">
              <v-list-item-icon>
                <v-icon>{{  item.icon  }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{  item.text  }}</v-list-item-title>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
    <!-- Top Bar -->
    <v-app-bar v-if="$route.meta.public" app height="48px" class="white" elevation="0">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-menu rounded open-on-hover offset-y transition="slide-x-transition" bottom right
        v-if="Notifications.length > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="(item, index) in Notifications" :key="index" router :to="item.link">
            <v-list-item-action>
              <v-list-item-title>{{  item.title  }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <v-tooltip bottom v-else>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </template><span>No Notifications</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" to="/profile">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template><span>Profile</span>
      </v-tooltip> -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="logout()">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template><span>Logout</span>
      </v-tooltip>
    </v-app-bar>
    <!-- Main -->
    <v-main>
      <v-divider></v-divider>
      <v-container fluid>
        <router-view :commonManage="commonManage"></router-view>
      </v-container>
    </v-main>
    <!-- Footer -->
    <v-footer app class="justify-center"> © Phoenixx Tech, UAE </v-footer>
  </v-app>
</template>

<script>
import WebService from "@/service/WebService";
import Menus from "./sidemenu.js";
export default {
  data: () => ({
    user_type_id: '',
    menu: [],
    Notifications: [],
    drawer: true,
  }),
  props: {
    source: String,
    Menus: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.user_type_id = localStorage.getItem("user_type_id");
    console.log(this.user_type_id);
    this.menu = Menus;
    if (localStorage.getItem("loggedIn") == "true") {
      this.getUser();
    } else this.$router.push("/");
  },
  methods: {
    getUser() {
      WebService.getCall("user").then((response) => {
        this.user_type_id = response.data.user_type_id;
        localStorage.setItem("user_type_id", this.user_type_id);
        localStorage.setItem("user_id", response.data.id);
        this.menu = Menus;
      });
    },
    commonManage(type) {
      switch (type) {
        case 1:
          //
          break;
        case 2:
          this.getUser();
          break;
      }
    },
    logout() {
      this.commonManage(1, true);
      WebService.getCall("logout").then((response) => {
        if (response.data.status == 200) {
          localStorage.setItem("loggedIn", false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("user_type_id");
          this.commonManage(1, false);
          this.$router.push({ name: "Login" });
        }
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
};
</script>