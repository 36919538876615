<template>
  <v-card flat>
    <v-row align="center" justify="center">
      <v-col cols="7">
        <apexchart type="line" :options="bar1.chartOptions" :series="bar1.series"></apexchart>
      </v-col>
      <v-col cols="5">
        <apexchart type="donut" width="480" :options="donut1.options" :series="donut1.series"></apexchart>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" v-if="user_type_id == 2">
      <v-col cols="7">
        <apexchart type="line" :options="bar2.chartOptions" :series="bar2.series"></apexchart>
      </v-col>
      <v-col cols="5">
        <apexchart type="donut" width="480" :options="donut2.options" :series="donut2.series"></apexchart>
      </v-col>
    </v-row>
    <common-manage ref="commonManage"></common-manage>
    <v-row v-if="user_type_id == 1">
      <v-col cols="12" md="3" xs="12">
        <v-card class="mx-auto" elevation="5" height="150" width="350" color="#1F7087" dark>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="6">
                <div class="float-right">
                  <v-icon size="120">mdi-account</v-icon>
                </div>
              </v-col>
              <v-col>
                <v-col><span style="font-size: 50px">{{ dashData.users }}</span></v-col>
                <v-divider></v-divider>
                <v-col>
                  <span style="font-size: 15px" class="float-right">USERS</span>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" xs="12">
        <v-card class="mx-auto" elevation="5" height="150" width="350" color="#952175" dark>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="6">
                <div class="float-right">
                  <v-icon size="120">mdi-google-analytics</v-icon>
                </div>
              </v-col>
              <v-col>
                <v-col><span style="font-size: 50px">{{ dashData.totalEnq }}</span></v-col>
                <v-divider></v-divider>
                <v-col>
                  <span style="font-size: 15px" class="float-right">TOTAL</span>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" xs="12">
        <v-card class="mx-auto" elevation="5" height="150" width="350" color="#385F73" dark>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="6">
                <div class="float-right">
                  <v-icon size="120">mdi-clipboard-check</v-icon>
                </div>
              </v-col>
              <v-col>
                <v-col><span style="font-size: 50px">{{ dashData.totalAssigned }}</span></v-col>
                <v-divider></v-divider>
                <v-col>
                  <span style="font-size: 15px" class="float-right">ASSIGNED</span>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" xs="12">
        <v-card class="mx-auto" elevation="5" height="150" width="350" color="#952175" dark>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="6">
                <div class="float-right">
                  <v-icon size="120">mdi-clipboard-minus</v-icon>
                </div>
              </v-col>
              <v-col>
                <v-col><span style="font-size: 50px">{{ dashData.totalUnassigned }}</span></v-col>
                <v-divider></v-divider>
                <v-col>
                  <span style="font-size: 15px" class="float-right">UNASSIGNED</span>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import WebService from "@/service/WebService";
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      user_type_id: '',
      bar1: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: false
            }
          },
          colors: ['#0d6efd', '#198754', '#dc3545', '#fd7e14'],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: 'Monthly Leads',
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '18px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238'
            },
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 1
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Month'
            }
          },
          yaxis: {
            title: {
              text: 'Leads'
            },
            min: 5,
            max: 40
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
          }
        },
      },
      donut1: {
        options: {
          labels: [],
          title: {
            text: 'Current Month Leads',
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '18px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238'
            },
          },
        },
        series: [],

      },
      bar2: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: false
            }
          },
          colors: ['#77B6EA', '#545454'],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: 'Monthly Leads',
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '18px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238'
            },
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 1
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Month'
            }
          },
          yaxis: {
            title: {
              text: 'Leads'
            },
            min: 5,
            max: 40
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
          }
        },
      },
      donut2: {
        options: {
          labels: [],
          title: {
            text: 'Current Month Leads',
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '18px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238'
            },
          },
        },
        series: [],

      },
      dashData: {},
    };
  },
  props: {
    commonManage: Function,
  },
  mounted() {
    this.user_type_id = localStorage.getItem("user_type_id")
    console.log(localStorage.getItem("user_type_id"));
    this.getDashData()
  },
  methods: {
    getDashData() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("dashboard").then((response) => {
        this.dashData = response.data.data;
        this.bar1.series = this.dashData.graph.series
        this.bar1.chartOptions = {
          ...this.bar1.chartOptions,
          ...{
            xaxis: {
              categories: this.dashData.graph.categories
            },
          },
        }
        this.donut1.series = this.dashData.donut.series
        this.donut1.options = {
          ...this.donut1.options,
          ...{
            labels: this.dashData.donut.label
          },
        }
        if (this.user_type_id == 2) {
          this.bar2.series = this.dashData.self_assigned.series
          this.bar2.chartOptions = {
            ...this.bar2.chartOptions,
            ...{
              xaxis: {
                categories: this.dashData.self_assigned.categories
              },
            },
          }
          this.donut2.series = this.dashData.donut_self.series
          this.donut2.options = {
            ...this.donut2.options,
            ...{
              labels: this.dashData.donut_self.label
            },
          }
        }
        this.$refs.commonManage.open(1, false);
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
};
</script>